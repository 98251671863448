import mutations from "./mutations";
import XeroService from "../../../../services/xero.service";

const state = () => ({
  data: "",
  loading: false,
  error: null,
});

const actions = {
  async incomeChecksTTData({ commit }, data) {
    return new Promise((resolve, reject) => {

      XeroService.incomeChecksTTData(data).then((response) => {
        if (response.status == 200) {
          //const data = response;
          //commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async incomeChecksTTIgnore({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.incomeChecksTTIgnore(data).then((response) => {
        if (response.status == 200) {
          const data = response;
          commit("readSuccess", data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignoreIncomeChecksTT({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ignoreIncomeChecksTT(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async incomeChecksTTBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.incomeChecksTTBulk(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
