const routes = [
  {
    path: "",
    name: "overview",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/Dashboard.vue"
      ),
    props: true,
  },
  {
    path: "/vat-checklist",
    name: "vat-checklist",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/VatDashboard.vue"
      ),
    props: true,
  },
  {
    path: "/help",
    name: "help",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/Help.vue"
      ),
    props: true,
  },
   
  // Masters

  {
    path: "/masters",
    name: "masters",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/masters/index.vue"
      ),
    children: [
      {
        path: "/dup_contact",
        name: "dup_contact",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/masters/XeroContact.vue"
          ),
        props: true,
      },

      {
        path: "/unuse_chart",
        name: "unuse_chart",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/masters/XeroUnusechart.vue"
          ),
        props: true,
      },

      {
        path: "/repeat_sinvoice",
        name: "repeat_sinvoice",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/masters/XeroSalesInvoice.vue"
          ),
        props: true,
      },
      {
        path: "/repeat_pinvoice",
        name: "repeat_pinvoice",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/masters/XeroPurchaseInvoice.vue"
          ),
        props: true,
      },
      {
        path: "/journal",
        name: "xero_journal",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/masters/XeroJournal.vue"
          ),
        props: true,
      },
    ],
  },

  // Balance Sheet
  {
    path: "/balance",
    name: "balance",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/index.vue"
      ),
    children: [
      {
        path: "/xero_unreconciled",
        name: "xero_unreconciled",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroUnreconciled.vue"
          ),
        props: true,
      },

      {
        path: "/xero_capitalizeinvoice",
        name: "xero_capitalizeinvoice",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroCaptalizeinvoice.vue"
          ),
        props: true,
      },

      {
        path: "/xero_balancesheet",
        name: "xero_balancesheet",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroBalancesheetacc.vue"
          ),
        props: true,
      },

      {
        path: "/xero_interestbooked",
        name: "xero_interestbooked",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroInterestbooked.vue"
          ),
        props: true,
      },

      {
        path: "/xero_manualbank",
        name: "xero_manualbank",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroManualbanktras.vue"
          ),
        props: true,
      },
      {
        path: "/xero_novat",
        name: "xero_novat",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroNoVat.vue"
          ),
        props: true,
      },
    ],
  },

  //Nominal
  {
    path: "/nominal",
    name: "nominal",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/index.vue"
      ),
    children: [
      

      {
        path: "/xero_vat",
        name: "xero_vat",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroVat.vue"
          ),
        props: true,
      },

      {
        path: "/xero_nominal",
        name: "xero_nominal",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/Xeronominal.vue"
          ),
        props: true,
      },

      {
        path: "/xero_nominalvat",
        name: "xero_nominalvat",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroNominalvat.vue"
          ),
        props: true,
      },

      {
        path: "/xero_transnominal",
        name: "xero_transnominal",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroTransnominal.vue"
          ),
        props: true,
      },
      {
        path: "/xero_supplier",
        name: "xero_supplier",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroSupplier.vue"
          ),
        props: true,
      },
      {
        path: "/xero_vatasset",
        name: "xero_vatasset",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroVatasset.vue"
          ),
        props: true,
      },

      {
        path: "/xero_vatjournal",
        name: "xero_vatjournal",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroVatjournal.vue"
          ),
        props: true,
      },
      
      {
        path: "/xero_pivotanalysis",
        name: "xero_pivotanalysis",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroPivotAnalysis.vue"
          ),
        props: true,
      },

      {
        path: "/xero_lateentry",
        name: "xero_lateentry",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroLateentries.vue"
          ),
        props: true,
      },

      {
        path: "/xero_trackingcat",
        name: "xero_trackingcat",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroTrackingCat.vue"
          ),
        props: true,
      },
      {
        path: "/xero_description",
        name: "xero_description",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroDescription.vue"
          ),
        props: true,
      },
      {
        path: "/xero_hmrc",
        name: "xero_hmrc",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroHmrc.vue"
          ),
        props: true,
      },
      {
        path: "/xero_revenue",
        name: "xero_revenue",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroRevenue.vue"
          ),
        props: true,
      },
      {
        path: "/xero_expense",
        name: "xero_expense",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroExpense.vue"
          ),
        props: true,
      },
      
      
    ],
  },

  // AP/AR
  {
    path: "/ap_ar",
    name: "ap_ar",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/index.vue"
      ),
    children: [
      {
        path: "/xero_dupinvoices",
        name: "xero_dupinvoices",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroInvoice.vue"
          ),
        props: true,
      },
      {
        path: "/xero_pay",
        name: "xero_pay",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroPay.vue"
          ),
        props: true,
      },

      {
        path: "/xero_debit",
        name: "xero_debit",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroDebit.vue"
          ),
        props: true,
      },
      {
        path: "/xero_credit",
        name: "xero_credit",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroCredit.vue"
          ),
        props: true,
      },

      {
        path: "/xero_supplierinvoices",
        name: "xero_supplierinvoices",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/Xerosupplierinvoices.vue"
          ),
        props: true,
      },

      {
        path: "/partial_pay",
        name: "partial_pay",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroPartialPayments.vue"
          ),
        props: true,
      },

      {
        path: "/receipts_allocation",
        name: "receipts_allocation",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroReceiptsAllocation.vue"
          ),
        props: true,
      },

      {
        path: "/xero_PIdraft",
        name: "xero_PIdraft",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroPIDraft.vue"
          ),
        props: true,
      },

      {
        path: "/xero_invoicematch",
        name: "xero_invoicematch",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroInvoicematch.vue"
          ),
        props: true,
      },

      {
        path: "/xero_dabtorinvoices",
        name: "xero_dabtorinvoices",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/Xerodebtorinvoices.vue"
          ),
        props: true,
      },
      {
        path: "/xero_payment_expensed",
        name: "xero_payment_expensed",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/Xeropaymentexpensed.vue"
          ),
        props: true,
      },
      {
        path: "/xero_receipt",
        name: "xero_receipt",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroReceipt.vue"
          ),
        props: true,
      },

      {
        path: "/xero_SIdraft",
        name: "xero_SIdraft",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroSIDraft.vue"
          ),
        props: true,
      },
      {
        path: "/xero_foreign",
        name: "xero_foreign",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroForeign.vue"
          ),
        props: true,
      },
      {
        path: "/xero_allocation",
        name: "xero_allocation",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroAllocation.vue"
          ),
        props: true,
      },
      {
        path: "/xero_mode",
        name: "xero_mode",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroPaymentMode.vue"
          ),
        props: true,
      },
      ],
    },
      // Dynamic Points
      {
    path: "/dynamic",
    name: "dynamic",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/index.vue"
      ),
    children: [
      {
        path: "/xero_Fainvoices",
        name: "xero_Fainvoices",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/XeroFainvoices.vue"
          ),
        props: true,
      },
      {
        path: "/xero_Entertainment",
        name: "xero_Entertainment",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/EntertainmentExpenses.vue"
          ),
        props: true,
      },
      {
        path: "/supplier-invoice",
        name: "supplier-invoice",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/SupplierInvoice.vue"
          ),
        props: true,
      },

      {
        path: "/value-invoice",
        name: "value-invoice",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/ValueInvoice.vue"
          ),
        props: true,
      },
      {
        path: "/xero_accrual",
        name: "xero_accrual",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/PotentialAccrual.vue"
          ),
        props: true,
      },
      {
        path: "/xero_prepayments",
        name: "xero_prepayments",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/PotentialPrepayments.vue"
          ),
        props: true,
      },
      {
        path: "/da_invoices",
        name: "da_invoices",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/XeroDainvoices.vue"
          ),
        props: true,
      },
      {
        path: "/vat_late",
        name: "vat_late",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/dynamic/VatLateclaims.vue"
          ),
        props: true,
      },
    ],
    },
// Vat Review
  {
    path: "/vat_review",
    name: "vat_review",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/index.vue"
      ),
    children: [
      {
        path: "/xero_unreconciled1",
        name: "xero_unreconciled1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroUnreconciled.vue"
          ),
        props: true,
      },

      {
        path: "/xero_capitalizeinvoice1",
        name: "xero_capitalizeinvoice1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroCaptalizeinvoice.vue"
          ),
        props: true,
      },
      {
        path: "/xero_novat1",
        name: "xero_novat1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/balance/XeroNoVat.vue"
          ),
        props: true,
      },
      {
        path: "/xero_vat1",
        name: "xero_vat1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroVat.vue"
          ),
        props: true,
      },

      {
        path: "/xero_nominal1",
        name: "xero_nominal1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/Xeronominal.vue"
          ),
        props: true,
      },

      {
        path: "/xero_nominalvat1",
        name: "xero_nominalvat1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroNominalvat.vue"
          ),
        props: true,
      },

      {
        path: "/xero_transnominal1",
        name: "xero_transnominal1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroTransnominal.vue"
          ),
        props: true,
      },
      {
        path: "/xero_vatjournal1",
        name: "xero_vatjournal1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroVatjournal.vue"
          ),
        props: true,
      },
      {
        path: "/xero_hmrc1",
        name: "xero_hmrc1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/nominal/XeroHmrc.vue"
          ),
        props: true,
      },
      {
        path: "/xero_dupinvoices1",
        name: "xero_dupinvoices1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroInvoice.vue"
          ),
        props: true,
      },
      {
        path: "/xero_pay1",
        name: "xero_pay1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroPay.vue"
          ),
        props: true,
      },

      {
        path: "/xero_debit1",
        name: "xero_debit1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroDebit.vue"
          ),
        props: true,
      },
      {
        path: "/xero_credit1",
        name: "xero_credit1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroCredit.vue"
          ),
        props: true,
      },

      {
        path: "/xero_supplierinvoices1",
        name: "xero_supplierinvoices1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/Xerosupplierinvoices.vue"
          ),
        props: true,
      },

      {
        path: "/partial_pay1",
        name: "partial_pay1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroPartialPayments.vue"
          ),
        props: true,
      },

      {
        path: "/receipts_allocation1",
        name: "receipts_allocation1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroReceiptsAllocation.vue"
          ),
        props: true,
      },

      {
        path: "/xero_PIdraft1",
        name: "xero_PIdraft1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroPIDraft.vue"
          ),
        props: true,
      },

      {
        path: "/xero_invoicematch1",
        name: "xero_invoicematch1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroInvoicematch.vue"
          ),
        props: true,
      },

      {
        path: "/xero_dabtorinvoices1",
        name: "xero_dabtorinvoices1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/Xerodebtorinvoices.vue"
          ),
        props: true,
      },
      {
        path: "/xero_payment_expensed1",
        name: "xero_payment_expensed1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/Xeropaymentexpensed.vue"
          ),
        props: true,
      },
      {
        path: "/xero_receipt1",
        name: "xero_receipt1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroReceipt.vue"
          ),
        props: true,
      },

      {
        path: "/xero_SIdraft1",
        name: "xero_SIdraft1",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/ap/XeroSIDraft.vue"
          ),
        props: true,
      },
      {
        path: "/input_vat_purchase",
        name: "input_vat_purchase",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/InputVatPurchase.vue"
          ),
        props: true,
      },
      {
        path: "/input_vat_asset",
        name: "input_vat_asset",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/InputVatAsset.vue"
          ),
        props: true,
      },
      {
        path: "/income_checks",
        name: "income_checks",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/IncomeChecks.vue"
          ),
        props: true,
      },
      {
        path: "/income_checkstt",
        name: "income_checkstt",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/IncomeChecksTT.vue"
          ),
        props: true,
      },
      {
        path: "/standard_rate_expense",
        name: "standard_rate_expense",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/StandardRateExpense.vue"
          ),
        props: true,
      },
      {
        path: "/reversecharge",
        name: "reversecharge",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/ReverseCharge.vue"
          ),
        props: true,
      },
      {
        path: "/staff_entertainment",
        name: "staff_entertainment",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/StaffEntertainment.vue"
          ),
        props: true,
      },
      {
        path: "/client_entertainment",
        name: "client_entertainment",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/ClientEntertainment.vue"
          ),
        props: true,
      },
      {
        path: "/no_vat_transactions",
        name: "no_vat_transactions",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/NoVat.vue"
          ),
        props: true,
      },
      {
        path: "/zero_rated_transactions",
        name: "zero_rated_transactions",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/ZeroRated.vue"
          ),
        props: true,
      },
      {
        path: "/vat_advertisement",
        name: "vat_advertisement",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/AdvertisementVat.vue"
          ),
        props: true,
      },
      {
        path: "/vat_subscription",
        name: "vat_subscription",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/SubscriptionVat.vue"
          ),
        props: true,
      },
      {
        path: "/vat_legal",
        name: "vat_legal",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/LegalVat.vue"
          ),
        props: true,
      },
      {
        path: "/vat_cis",
        name: "vat_cis",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/CisVat.vue"
          ),
        props: true,
      },
      {
        path: "/vat_lease",
        name: "vat_lease",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/LeaseRentalVat.vue"
          ),
        props: true,
      },
      {
        path: "/vat_mot",
        name: "vat_mot",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/categories/vat/MotVat.vue"
          ),
        props: true,
      },
    ],
  },
      
      
    
  
];

export default routes;
