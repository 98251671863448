import mutations from "./mutations";
import XeroService from "../../../../services/xero.service";

const state = () => ({
  data: "",
  loading: false,
  error: null,
});

const actions = {
  async inputVatPurchaseData({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.inputVatPurchaseData(data).then((response) => {
        if (response.status == 200) {
          //const data = response;
          //commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async inputVatPurchaseIgnore({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.inputVatPurchaseIgnore(data).then((response) => {
        if (response.status == 200) {
          const data = response;
          commit("readSuccess", data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignoreInputVatPurchase({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ignoreInputVatPurchase(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async inputVatPurchaseBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.inputVatPurchaseBulk(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
