const routes = [
  {
    path: "/new-dashboard",
    name: "new-dashboard",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/overview/newDashboard.vue"
      ),
    props: true,
  },
  
];

export default routes;
