import mutations from "./mutations";
import XeroService from "../../../../services/xero.service";

const state = () => ({
  data: "",
  loading: false,
  error: null,
});

const actions = {
  async ReverseChargeData({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ReverseChargeData(data).then((response) => {
        if (response.status == 200) {
          //const data = response;
          //commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ReverseChargeIgnore({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ReverseChargeIgnore(data).then((response) => {
        if (response.status == 200) {
          const data = response;
          commit("readSuccess", data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignoreReverseCharge({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ignoreReverseCharge(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ReverseChargeBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ReverseChargeBulk(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },
  async StaffEntertainmentData({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.StaffEntertainmentData(data).then((response) => {
        if (response.status == 200) {
          //const data = response;
          //commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async StaffEntertainmentIgnore({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.StaffEntertainmentIgnore(data).then((response) => {
        if (response.status == 200) {
          const data = response;
          commit("readSuccess", data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignoreStaffEntertainment({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ignoreStaffEntertainment(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async StaffEntertainmentBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.StaffEntertainmentBulk(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },
  async ClientEntertainmentData({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ClientEntertainmentData(data).then((response) => {
        if (response.status == 200) {
          //const data = response;
          //commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ClientEntertainmentIgnore({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ClientEntertainmentIgnore(data).then((response) => {
        if (response.status == 200) {
          const data = response;
          commit("readSuccess", data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignoreClientEntertainment({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ignoreClientEntertainment(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ClientEntertainmentBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ClientEntertainmentBulk(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },
  async StandardExpensesData({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.StandardExpensesData(data).then((response) => {
        if (response.status == 200) {
          //const data = response;
          //commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async StandardExpensesIgnore({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.StandardExpensesIgnore(data).then((response) => {
        if (response.status == 200) {
          const data = response;
          commit("readSuccess", data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignoreStandardExpenses({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ignoreStandardExpenses(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async StandardExpensesBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.StandardExpensesBulk(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
